import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/HomePage/Home";
import Pricing from "./pages/PricingPage/Pricing";
import SignIn from "./pages/SignInPage/SignIn";
import ForgotPassword from "./pages/ForgotPasswordPage/ForgotPassword";
import AboutUs from "./pages/AbousUsPage/AboutUs";
import SignUp from "./pages/SignUpPage/SignUp";
import ResetPassword from "./pages/ResetPasswordPage/ResetPassword";
import AdminDashboard from "./pages/AdminDashboardPage/AdminDashboard";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import { GoogleAuthProviderWrapper } from "./utils/GoogleAuthProvider";
import { MicrosoftAuthProvider } from "./utils/MicrosoftAuthProvider";
import EmailActivation from "./pages/EmailActivationPage/EmailActivation";
import VerifyEmail from "./pages/VerifyEmailPage/VerifyEmail";
import { AuthProvider } from "./utils/AuthProvider";
import ProtectedRoute from "./utils/ProtectedRoute";
import NotFound from "./pages/NotFoundPage/NotFound";
import ComingSoon from "./pages/ComingSoonPage/ComingSoon";
import Footer from "./components/Footer/Footer";
import Dashboard from "./pages/DashboardPage/Dashboard";
import UserPage from "./pages/UserPage/UserPage";
import ReactGA from "react-ga4";
import TawkTo from "./utils/TawkTo";
import Sources from "./pages/SourcesPage/Sources";
import PaymentSuccess from "./pages/UserPage/PaymentSuccess";
import PaymentFail from "./pages/UserPage/PaymentFail";
import FindAccountant from "./pages/FindAccountant/FindAccountant";

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-RVSGB8C8Y7", { debug: true });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

function AppContent() {
  usePageTracking();
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/user/reset-password" element={<ResetPassword />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/email/forgot-password" element={<ForgotPassword />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route
        path="/app"
        element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        }
      />
      <Route path="/email/otp/" element={<EmailActivation />} />
      <Route
        path="/user-page"
        element={
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        }
      />
      <Route path="/sources" element={<Sources />} />
      <Route path="/payment/success" element={<PaymentSuccess />} />
      <Route path="/payment/fail" element={<PaymentFail />} />
      <Route path="/accountant" element={<FindAccountant />} />

      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
      <Route path="/footer" element={<Footer />} />
    </Routes>
  );
}

function App() {
  return (
    // <GoogleAuthProviderWrapper>
    <MicrosoftAuthProvider>
      <Router>
        <TawkTo />
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </MicrosoftAuthProvider>
    // </GoogleAuthProviderWrapper>
  );
}

export default App;
