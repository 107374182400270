import React from "react";
import AccountantCard from "./AccountantCard";

const AllAccountants = ({
  currentAccountants,
  handleSelectAccountant,
  loadingId,
}) => {
  console.log(currentAccountants);
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center md:text-left">
        All Accountants
      </h2>
      {currentAccountants.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {currentAccountants.map((accountant) => (
            <AccountantCard
              key={accountant.id}
              accountant={accountant}
              handleSelectAccountant={handleSelectAccountant}
              loadingId={loadingId}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">
          No accountants match your search criteria.
        </p>
      )}
    </div>
  );
};

export default AllAccountants;
