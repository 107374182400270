import React, { useState, useEffect, useRef } from "react";
import {
  FaPaperclip,
  FaInfoCircle,
  FaTimes,
  FaMicrophone,
  FaArrowRight,
} from "react-icons/fa";
import { encode } from "gpt-tokenizer";
import api from "../../../utils/api";
import { useAuth } from "../../../utils/AuthProvider";

const MessageInput = ({
  userLevel,
  newMessage,
  setNewMessage,
  handleSendMessage,
  handleFileChange,
  fileInputRef,
  handleUpload,
  setModalOpen,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tokenCount, setTokenCount] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [showExceededWarning, setShowExceededWarning] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { token } = useAuth();
  const textRef = useRef(null);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    textRef.current.style.height = "auto";
    textRef.current.style.height = `${Math.min(
      textRef.current.scrollHeight,
      125
    )}px`;
    updateTokenCount(e.target.value);
  };
  const onFileChange = (event) => {
    const file = event.target.files[0];
    // Maximum file size in bytes (25 MB)
    const maxFileSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxFileSize) {
        setShowErrorModal(true);
        event.target.value = null;
        return;
      }
      setSelectedFile(file);
      handleFileChange(event);
    }
  };

  const closeModal = () => setShowErrorModal(false);

  const removeFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };
  const onSendClick = () => {
    const totalTokenCount = tokenCount + wordCount;
    console.log(wordCount);
    if (
      newMessage.trim() !== "" &&
      (wordCount <= 5000 || userLevel === "premium" || userLevel === "employee")
    ) {
      handleSendMessage(newMessage, selectedFile, "user", tokenCount);
      textRef.current.style.height = "50px";

      setNewMessage("");
      setSelectedFile(null);
      fileInputRef.current.value = null;

      setWordCount((prevWordCount) => prevWordCount + tokenCount);

      // if (totalTokenCount + tokenCount > 5000) {
      //   setShowExceededWarning(true);
      // }
    }
  };

  const updateTokenCount = (message) => {
    const tokens = encode(message);
    setTokenCount(tokens.length);
  };

  useEffect(() => {
    if (wordCount > 5000) {
      setShowExceededWarning(true);
    }
  }, [wordCount]);
  return (
    <div className="pt-4 px-4 bg-gradient-to-r from-blue-50 to-green-50 border-t border-gray-200 rounded-lg shadow-md">
      {showExceededWarning && userLevel == "free" && (
        <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex items-center">
          <span className="block sm:inline text-xs font-medium">
            ⚠️ You've reached the 5000-token limit for your free account. To
            continue the conversation without interruptions, Please{" "}
            <span
              className="cursor-pointer text-blue-600 underline"
              onClick={() => setModalOpen(true)}
            >
              upgrade to our premium plan.
            </span>
          </span>
        </div>
      )}

      <div className="flex items-center ">
        <div className="relative w-full sm:max-w-auto">
          <textarea
            ref={textRef}
            className={`w-full p-3  mb-1 rounded-lg focus:outline-none focus:ring-2 resize-none text-sm pr-10 shadow-inner bg-white placeholder-gray-500 ${
              showExceededWarning && userLevel == "free"
                ? "border-red-500 focus:ring-red-500"
                : "focus:ring-green-400"
            }`}
            placeholder="Type your message here... 💬"
            value={newMessage}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (e.shiftKey) {
                  return;
                } else {
                  if (
                    (!showExceededWarning ||
                      userLevel == "premium" ||
                      userLevel == "employee") &&
                    newMessage !== ""
                  ) {
                    e.preventDefault();
                    onSendClick();
                  } else {
                    e.preventDefault();
                  }
                }
              }
            }}
            rows="1"
            style={{ maxHeight: "125px", overflow: "auto", height: "auto" }}
            disabled={showExceededWarning && userLevel == "free"}
          />
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={onFileChange}
            accept=".pdf,.docx,.pptx, .txt, .xlsx"
          />
          <button
            type="button"
            className="absolute top-[14px] right-3 sm:right-4 pb-1 flex items-center text-gray-500 hover:text-green-500"
            onClick={handleUpload}
            onMouseEnter={() =>
              // userLevel !== "premium" &&
              // userLevel !== "employee" &&
              setShowTooltip(true)
            }
            onMouseLeave={() => setShowTooltip(false)}
          >
            <FaPaperclip size={18} />
          </button>
          {userLevel !== "premium" && userLevel !== "employee"
            ? showTooltip && (
                <div className="absolute bottom-10 right-2 sm:right-4 bg-gray-700 text-white text-xs p-2 rounded-md shadow-md">
                  This feature is available for premium users only
                </div>
              )
            : showTooltip && (
                <div className="absolute bottom-10 right-2 sm:right-4 bg-gray-800 text-white text-xs p-3 rounded-md shadow-lg max-w-xs sm:max-w-sm break-words">
                  <span>Files larger than 25 MB cannot be uploaded.</span>
                </div>
              )}
        </div>

        <button
          className="px-3 ml-2 bg-green-400 hover:bg-green-500 text-white rounded shadow w-auto min-h-10 text-sm relative mb-2"
          onClick={onSendClick}
          disabled={showExceededWarning && userLevel === "free"}
        >
          {newMessage.trim() === "" && !selectedFile ? (
            <FaMicrophone size={16} />
          ) : (
            <FaArrowRight size={16} />
          )}
        </button>
      </div>

      {selectedFile && (
        <div className="flex items-center mt-3 text-sm text-gray-700 bg-gray-100 p-2 rounded-lg shadow-inner">
          <p className="mr-2">📁 {selectedFile.name}</p>
          <FaTimes
            className="text-red-500 cursor-pointer hover:text-red-600"
            onClick={removeFile}
          />
        </div>
      )}

      <div className="my-1 flex justify-between items-center">
        <p className="text-center text-xs flex-1 text-gray-600">
          Get better responses by being specific and clear in your queries.
        </p>
        <FaInfoCircle
          className="ml-2 text-gray-500 hover:text-green-500 cursor-pointer"
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
        />
        {showInfo && (
          <div className="absolute bottom-10 right-[2%] bg-white border border-gray-300 rounded-md p-4 shadow-lg w-72 sm:w-96 text-xs z-10 transition-transform duration-200 ease-in-out transform translate-y-2">
            <div className="space-y-3 text-gray-700">
              <div>
                <strong>Goal:</strong> Clearly state what you want to achieve
                with your prompts.
                <p className="text-gray-600 italic">
                  Example: "I need help with financial ratios for my quarterly
                  report."
                </p>
              </div>
              <div>
                <strong>Context:</strong> Provide background information to help
                the bot understand your needs.
                <p className="text-gray-600 italic">
                  Example: "I'm preparing taxes for a small business using
                  cash-basis accounting."
                </p>
              </div>
              <div>
                <strong>Instruction:</strong> Include details or data to make
                the response accurate.
                <p className="text-gray-600 italic">
                  Example: "Provide citations and legal references related to
                  business taxation in the Philippines."
                </p>
              </div>
              <div>
                <strong>Presentation:</strong> Organize your input clearly to
                avoid confusion.
                <p className="text-gray-600 italic">
                  Example: "I'm preparing for an audit and need help with:
                  <ul className="list-decimal pl-3">
                    <li>
                      Reviewing accounts receivable balances for accuracy.
                    </li>
                    <li>Verifying the completeness of expense reports.</li>
                    <li>
                      Ensuring proper classification of capital and operational
                      expenses. Can you provide guidance on these areas?
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {showErrorModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold text-red-500 mb-4">Error</h2>
            <p className="mb-4">
              File size exceeds 25 MB. Please upload a smaller file.
            </p>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageInput;
