import React from "react";
import AccountantCard from "./AccountantCard";

const TopRatedAccountants = ({
  topAccountants,
  handleSelectAccountant,
  loadingId,
}) => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center md:text-left">
        Top Rated Accountants
      </h2>
      {topAccountants.length > 0 ? (
        <div className="relative overflow-x-auto flex space-x-6 pb-4">
          {topAccountants.map((accountant) => (
            <AccountantCard
              key={accountant.id}
              accountant={accountant}
              handleSelectAccountant={handleSelectAccountant}
              loadingId={loadingId}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">
          No top-rated accountants available.
        </p>
      )}
    </div>
  );
};

export default TopRatedAccountants;
