import React, { useState, useEffect } from "react";
import SearchFilter from "./components/SearchFilter";
import TopRatedAccountants from "./components/TopRated";
import AllAccountants from "./components/AllAccountants";
import api from "../../utils/api";
import Pagination from "./components/Pagination";

export default function FindAccountant() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");

  const [accountants, setAccountants] = useState([]);
  const [filteredAccountants, setFilteredAccountants] = useState([]);
  const [topAccountants, setTopAccountants] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const [loadingId, setLoadingId] = useState(null);
  useEffect(() => {
    const fetchLevel = async () => {
      try {
        const response = await api.get(`/accountant/all`);

        console.log(response);
        setAccountants(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchLevel();
  }, []);
  console.log(accountants);
  useEffect(() => {
    let filtered = accountants;

    if (filter) {
      filtered = filtered.filter(
        (accountant) => accountant.expertise === filter
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(
        (accountant) =>
          accountant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          accountant.expertise.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sort === "desc") {
      filtered = filtered.sort((a, b) => b.rating - a.rating);
    } else if (sort === "asc") {
      filtered = filtered.sort((a, b) => a.rating - b.rating);
    }

    setFilteredAccountants(filtered);
  }, [searchTerm, filter, sort, accountants]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAccountants = filteredAccountants.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredAccountants.length / itemsPerPage);
  // Function to handle viewing accountant profile
  const handleSelectAccountant = (id) => {
    setLoadingId(id);
    setTimeout(() => {
      console.log("View Profile for Accountant ID:", id);
      setLoadingId(null);
    }, 1500);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <SearchFilter
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
      <TopRatedAccountants
        topAccountants={topAccountants}
        handleSelectAccountant={handleSelectAccountant}
        loadingId={loadingId}
      />
      <AllAccountants
        currentAccountants={currentAccountants}
        handleSelectAccountant={handleSelectAccountant}
        loadingId={loadingId}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
