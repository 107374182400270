import React from "react";

const SearchFilter = ({
  searchTerm,
  setSearchTerm,
  filter,
  setFilter,
  sort,
  setSort,
}) => {
  return (
    <div className="mb-8 w-full">
      <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center md:text-left">
        Find an Accountant
      </h1>
      <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
        <input
          type="text"
          placeholder="Search by skill, location, or name..."
          className="border p-3 w-full md:w-2/3 rounded-lg text-lg focus:ring-2 focus:ring-blue-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="border p-3 rounded-lg text-gray-600 w-full md:w-1/3"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="">Filter by Expertise</option>
          <option value="Tax Advisor">Tax Advisor</option>
          <option value="Financial Auditor">Financial Auditor</option>
        </select>
        <select
          className="border p-3 rounded-lg text-gray-600 w-full md:w-1/3"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value="">Sort by Rating</option>
          <option value="desc">Highest to Lowest</option>
          <option value="asc">Lowest to Highest</option>
        </select>
      </div>
    </div>
  );
};

export default SearchFilter;
