import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "../../utils/api";
import { useAuth } from "../../utils/AuthProvider";
import Payments from "./components/Payments";
import Profile from "./components/Profile";
import SideBar from "../../components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import Header from "./components/HeaderUserdashboard";
import MessageList from "./components/MessageList";
import PredefinedQuestions from "./components/PredefinedQuestions";
import MessageInput from "./components/MessageInput";
import questions from "./questions.json";
import { encode } from "gpt-tokenizer";

export default function UserDashboard() {
  const { logout, token } = useAuth();
  const [threadId, setThreadId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userLevel, setUserLevel] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  const fetchMessagesRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const eventSourceRef = useRef(null);

  useEffect(() => {
    const fetchLevel = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/level`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserLevel(response.data.user_level);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchLevel();
  }, [token]);
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeModalProf = () => setModalOpenProfile(false);

  const fetchThreadMessages = useCallback(
    async (threadId) => {
      setLoading(true);
      try {
        const response = await api.get(`/chat/history/thread/${threadId}`);
        const fetchedMessages = response.data;
        if (fetchedMessages && fetchedMessages.length > 0) {
          setMessages((prevMessages) => {
            const newMessages = fetchedMessages.filter(
              (msg) =>
                !prevMessages.some((existingMsg) => existingMsg.id === msg.id)
            );
            const updatedMessages = [...prevMessages, ...newMessages];
            // localStorage.setItem(`messages_${threadId}`, JSON.stringify(updatedMessages));
            return updatedMessages;
          });
        } else {
          console.warn("No messages found for the thread.");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    },
    [threadId]
  );

  useEffect(() => {
    const fetchWordCount = async () => {
      try {
        const response = await api.get(`/chat/token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWordCount(response.data?.chat_token || 0);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    if (token) {
      fetchWordCount();
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchHistory = async (retryCount = 3) => {
      setLoading(true);
      try {
        const response = await api.get(`/chat/history/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
        const fetchedHistory = response.data;
        if (fetchedHistory && fetchedHistory.length > 0) {
          setThreadId(fetchedHistory[0].thread_id);
          sessionStorage.setItem("threadId", fetchedHistory[0].thread_id);
        } else {
          console.warn("No threads found.");
          const response = await api.get(`/chat/new/thread`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setThreadId(response.data.thread_id);
          sessionStorage.setItem(
            "threadId",
            response.data.thread_id.toString()
          );
          setRefresh(!refresh);
        }
      } catch (error) {
        console.error("Error getting history:", error);

        if (error.response && error.response.status === 500 && retryCount > 0) {
          console.warn(`Retrying... ${retryCount} attempts left.`);
          setTimeout(() => fetchHistory(retryCount - 1), 3000);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: "system",
              text_value:
                "An error occurred while fetching the chat history. Please try again later.",
            },
          ]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [token]);

  const handleSendMessage = async (
    message,
    file,
    sender = "user",
    tokenCount
  ) => {
    const trimmedMessage = message.trim();
    const selectedFile = fileInputRef.current.files[0];

    setNewMessage("");
    setHasError(false);

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: sender,
        text_value: trimmedMessage,
        file: file ? file.name : null,
      },
      { role: "assistant", text_value: "" },
    ]);

    setIsLoading(true);

    let formData = new FormData();
    formData.append("thread_id", threadId);
    formData.append("message", trimmedMessage);

    if (file) {
      formData.append("file", selectedFile);
    }

    try {
      await api.post(`/chat/response/assistant`, formData);
      eventSourceRef.current = new EventSource(
        `https://uatf.babylon2k.org/chat/stream/v2?thread_id=${threadId}&token=${token}`
      );

      eventSourceRef.current.onopen = async () => {
        await api.post(
          `/chat/update/token?chat_token=${tokenCount}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      };

      let accumulatedText = "";
      let isFirstResponse = true;

      eventSourceRef.current.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        if (eventData.event === "text_created") {
          if (isFirstResponse) {
            isFirstResponse = false;
            accumulatedText = "";
          } else {
            accumulatedText = eventData.data;
          }
        } else if (eventData.event === "text_delta") {
          if (!isFirstResponse) {
            accumulatedText += eventData.data;
          }
        }

        const cleanedText = accumulatedText.replace(/【[^】]*】/g, "").trim();

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].text_value = cleanedText;
          return updatedMessages;
        });

        if (eventData.event === "text_created") {
          setIsLoading(false);
        }
      };

      eventSourceRef.current.onerror = () => {
        eventSourceRef.current.close();
        fetchThreadMessages(threadId);
        setIsLoading(false);
        setHasError(true);
      };
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  };
  const stopStream = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close the stream
      console.log("Stream stopped by user.");
      setIsLoading(false); // Stop the loading indicator
      setHasError(false); // Optionally reset any error state
    }
  };

  const handleRegenerate = () => {
    const lastMessage = messages[messages.length - 2]?.text_value || "";
    const tokenCount = encode(lastMessage).length;
    const lastFile = fileInputRef.current?.files[0] || null;
    handleSendMessage(lastMessage, lastFile, "user", tokenCount);
  };

  const handlePredefinedQuestionClick = (question) => {
    const tokenCount = encode(question).length;
    if (
      wordCount <= 5000 ||
      userLevel === "premium" ||
      userLevel === "employee"
    ) {
      handleSendMessage(question, null, "user", tokenCount);
    }
  };

  useEffect(() => {
    if (threadId) {
      fetchThreadMessages(threadId);
    }
  }, [threadId, fetchThreadMessages]);

  const handleUpload = () => {
    if (userLevel === "premium" || userLevel === "employee") {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
    }
  };

  return (
    <div className="flex h-screen text-black">
      {/* Sidebar */}
      <div
        className={`flex flex-col h-screen ${
          sidebarVisible ? "sm:w-64" : "w-0"
        } sm:transition-width sm:duration-500`}
      >
        <div
          className={`h-full sm:w-64 bg-white transform transition-transform duration-500 z-30 ${
            sidebarVisible ? "translate-x-0" : "-translate-x-full"
          } sm:relative fixed`}
        >
          <div className="flex flex-col h-screen overflow-y-auto ">
            {sidebarVisible && (
              <SideBar
                key={refresh}
                userLevel={userLevel}
                token={token}
                threadId={threadId}
                setThreadId={setThreadId}
                setMessages={setMessages}
                sidebarVisible={sidebarVisible}
                setSidebarVisible={setSidebarVisible}
                openModal={openModal}
                closeModal={closeModal}
                setModalOpenProfile={setModalOpenProfile}
              />
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-grow h-screen w-screen ">
        {/* Header */}
        <div className="sticky top-0 z-20 bg-white border-b border-gray-200 shadow-sm">
          <Header
            userLevel={userLevel}
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            logout={logout}
            wordCount={wordCount}
            openModal={openModal}
          />
        </div>

        {/* Content Area */}
        <div className="flex flex-col flex-grow h-screen overflow-y-auto">
          <div
            className="p-4 flex-grow overflow-auto border-r border-gray-300"
            style={{ height: "calc(80vh - 64px)" }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <MessageList
                  messages={messages}
                  isLoading={isLoading}
                  messagesEndRef={messagesEndRef}
                  hasError={hasError}
                  handleRegenerate={handleRegenerate}
                  stopStream={stopStream}
                />
                {messages.length === 0 && !loading && (
                  <PredefinedQuestions
                    predefinedQuestions={questions}
                    handlePredefinedQuestionClick={
                      handlePredefinedQuestionClick
                    }
                  />
                )}
              </>
            )}
          </div>
          <MessageInput
            userLevel={userLevel}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSendMessage={handleSendMessage}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            handleUpload={handleUpload}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>

      <Payments
        userLevel={userLevel}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <Profile
        userLevel={userLevel}
        modalOpen={modalOpenProfile}
        closeModal={closeModalProf}
        logout={logout}
        user={userLevel}
      />
    </div>
  );
}
