import React from "react";

const AccountantCard = ({ accountant, handleSelectAccountant, loadingId }) => {
  console.log(accountant);
  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden">
      {/* Image and Accreditation Badge */}
      <div className="relative">
        <img
          src={accountant.profilePic || "https://via.placeholder.com/150"} // Fallback image
          alt={accountant.name}
          className="w-full h-40 sm:h-48 md:h-56 object-cover"
        />
        <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs px-3 py-1 rounded-full shadow-lg">
          {accountant.accreditation || "No Accreditation"}
        </div>
      </div>

      {/* Accountant Information */}
      <div className="p-4 flex flex-col justify-between">
        {/* Name and Contact */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-gray-800 truncate">
            {accountant.name || "N/A"}
          </h3>
          <p className="text-gray-500 text-sm mt-1">
            {accountant.phone
              ? `📞 ${accountant.phone}`
              : "Phone number not available"}
          </p>
          <p className="text-gray-500 text-sm mt-1 truncate">
            {accountant.user_email || "No email provided"}
          </p>
        </div>

        {/* Rating and Rate */}
        <div className="flex justify-between items-center mb-4">
          <span className="text-yellow-400 text-sm font-semibold flex items-center">
            ⭐{" "}
            {accountant.reviews ? accountant.reviews.toFixed(1) : "No Reviews"}
          </span>
          <span className="text-lg font-bold text-gray-900">
            {accountant.rate ? `${accountant.rate} /hr` : "Rate not available"}
          </span>
        </div>

        {/* View Profile Button */}
        <div className="mt-auto">
          {" "}
          {/* Ensure button stays at the bottom */}
          <button
            onClick={() => handleSelectAccountant(accountant.accountant_id)}
            className={`w-full bg-blue-600 text-white text-sm py-2 rounded-md transition-all duration-200 
          ${
            loadingId === accountant.accountant_id
              ? "bg-blue-300 cursor-not-allowed"
              : "hover:bg-blue-700"
          }
        `}
            disabled={loadingId === accountant.accountant_id}
          >
            {loadingId === accountant.accountant_id
              ? "Loading..."
              : "View Profile"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountantCard;
